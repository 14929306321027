import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Lesson } from './lesson';
import { GalleryItem } from './gallery-item';

@Injectable({
  providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    const lessons = [
      {
        id: 1,
        title: 'Group Activity',
        description: 'Group activity',
        videoId: 'MNgTBtTz_Qo'
      },
      {
        id: 2,
        title: 'Learn Shapes',
        description: 'Learn shapes',
        videoId: 'MnOKD_I6vSU'
      },
      {
        id: 3,
        title: 'Parts of a Plant',
        description: 'Parts of a Plant',
        videoId: 'A-xScqCN0GA'
      },
      {
        id: 4,
        title: 'Metamorphosis - The Great Tansformation',
        description: 'Metamorphosis - The Great Tansformation',
        videoId: 'Lm-s168rW4s'
      },
      {
        id: 5,
        title: 'Months of the Year Song',
        description: 'Months of the Year Song',
        videoId: 'Fe9bnYRzFvk'
      },
      {
        id: 6,
        title: 'Seven Continents Song',
        description: 'Seven Continents Song',
        videoId: 'K6DSMZ8b3LE'
      },
    ];
    const galleryItems = [
      {
        id: 1,
        src: 'assets/images/girlwithbook.jpg',
        alt: 'girl with a book',
        description: 'Girl with a book'
      },
      {
        id: 2,
        src: 'assets/images/teachingskills.jpg',
        alt: 'teaching skills',
        description: 'teaching skills'
      },
      {
        id: 3,
        src: 'assets/images/readingtime.png',
        alt: 'reading time',
        description: 'reading time'
      },
      {
        id: 4,
        src: 'assets/images/drawing.jpg',
        alt: 'girl drawing',
        description: 'girl drawing'
      },
      {
        id: 5,
        src: 'assets/images/readingtime2.jpg',
        alt: 'more reading time',
        description: 'more reading time'
      },
      {
        id: 6,
        src: 'assets/images/arttime.jpg',
        alt: 'art lessons',
        description: 'art lessons'
      },
      {
        id: 7,
        src: 'assets/images/artcorner.jpg',
        alt: 'art supplies and aprons',
        description: 'art supplies and aprons'
      },
      {
        id: 8,
        src: 'assets/images/girlwithbook2.jpg',
        alt: 'girl reading',
        description: 'girl reading'
      }
    ];

    return {lessons, galleryItems};
  }

  // Overrides the genId method to ensure that a collection always has an id.
  // If the collection array is empty,
  // the method below returns the initial number (1).
  // if the collection array is not empty, the method below returns the highest
  // collection id + 1.

  genId<T extends Lesson | GalleryItem>(myTable: T[]): number {
    return myTable.length > 0 ? Math.max(...myTable.map(t => t.id)) + 1 : 1;
  }
}