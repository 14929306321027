<div class="container">
  <div class="row">
    <div class="col">
      <h1 class="text-center">Welcome to Jumpstart Preschool</h1>
      <h3 class="text-center">Quality preschool in West Jordan, UT</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <img src="assets/images/crayons.gif" class="rounded mx-auto d-block" alt="teaching skills"><br>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h2>Our Mission</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tincidunt justo accumsan mattis cursus.
        Curabitur at lectus quis risus congue tincidunt vel non velit. Sed sit amet neque mattis, pellentesque justo sit
        amet, efficitur diam. Pellentesque purus dui, lobortis quis interdum eget, blandit at ligula. Suspendisse
        vulputate leo quis massa tincidunt accumsan. Donec suscipit sodales massa, sit amet dapibus ipsum tincidunt
        eget. Nulla porta dignissim vestibulum. Sed justo lacus, auctor eu ligula sit amet, dapibus tincidunt arcu.
        Aliquam ac orci eleifend metus ultricies iaculis. Integer sed lobortis odio. In euismod, eros non semper
        finibus, augue arcu maximus diam, sed pulvinar dolor dolor a risus. Nam at tellus at tortor tincidunt dignissim.
        </p>
    </div>
    <div class="col">
      <h2>Our Commitment To You</h2>
      <p>Vivamus ut augue dapibus, suscipit est eu, feugiat mauris. Mauris sed convallis nunc. Sed placerat luctus
        placerat. Maecenas et nunc in lectus consectetur vulputate id eget nunc. In pretium odio sit amet neque euismod,
        quis tincidunt elit mattis. Donec ut odio lectus. Proin ex sapien, pulvinar a dui vitae, pretium malesuada erat.
        Nulla et faucibus elit. </p>
      <ul>
        <li>Nullam nec mauris et ipsum blandit placerat non eu diam.</li>
        <li>Sed ut sapien sit amet mauris porta maximus.</li>
        <li>Vivamus sollicitudin risus vel ipsum mattis laoreet.</li>
        <li>Integer posuere nunc sed metus scelerisque suscipit.</li>
      </ul>
    </div>
  </div>
</div>