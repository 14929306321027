import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  name: string;
  email: string;
  message: string;

  constructor() { }

  ngOnInit(): void {
  }

  submitForm(formData: NgForm) {
    console.log("Name: " + this.name + "  Email: " + this.email + "  Message: " + this.message);
    alert("Thank you for contacting us " + this.name + ", your message has been sent.")
    formData.reset();
  }

}
