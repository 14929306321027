import { Component, OnInit } from '@angular/core';
import { GalleryItemService } from '../gallery-item.service';
import { GalleryItem } from '../gallery-item';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  galleryItems: GalleryItem[] = [];

  constructor(private galleryItemService: GalleryItemService) { }

  ngOnInit(): void {
    this.getGalleryItems();
  }

  getGalleryItems(): void {
    this.galleryItemService.getGalleryItems()
        .subscribe(galleryItems => this.galleryItems = galleryItems);
  }
}
