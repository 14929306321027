<div class="container">
  <div class="row">
    <div class="col-lg">
      <h1 class="text-center">How To Contact Us</h1>
      <img src="assets/images/hand-drawing-with-pencils-small.jpg" class="rounded mx-auto d-block" alt="child hand drawing with pencils">
    </div>
  </div>
  <div class="row">
    <div class="col-md">
      <h2>Send Us a Message:</h2>

      <!-- contact form -->
      <form name="contactForm" #theForm="ngForm" (ngSubmit)="submitForm(theForm)">

        <!-- name -->
          <label for="inputName">Name</label>
          <input type="text" name="inputName" ngModel class="form-control" placeholder="Your name" [(ngModel)]="name"
            #inputName="ngModel" required>
          <div class="text-danger" *ngIf="inputName.invalid && inputName.touched">
            Name is required.
          </div>

        <!-- email -->
          <label class="label" for="inputEmail">Email address</label>
          <input type="email" name="inputEmail" ngModel class="form-control" aria-describedby="emailHelp"
            placeholder="Your email address" [(ngModel)]="email" #inputEmail="ngModel" required email>
          <div class="text-danger" *ngIf="inputEmail.invalid && inputEmail.touched">
            Your email address is required and needs to be a valid email address.
          </div>
          <small id="emailHelp" class="form-text text-muted">
            Your email address will only be used for this communication.
          </small>

        <!-- message -->
          <label for="inputMessage">Message</label>
          <textarea name="inputMessage" ngModel class="form-control" rows="3" [(ngModel)]="message" #inputMessage="ngModel"
            required>
          </textarea>
          <div class="text-danger" *ngIf="inputMessage.invalid && inputMessage.touched">
            Please type a message.
          </div><br>

        <!-- submit button -->
          <button type="submit" class="btn btn-primary" [disabled]="theForm.invalid">
            Send
          </button>
        
      </form>

    </div>
    <div class="col-md">
      <h2>Our Location:</h2>
      <iframe class="mapFrame" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.926645785656!2d-112.01701318434641!3d40.631499650472904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87528e77d4d17b03%3A0x5e639e479f9d3c94!2s5194%20W%206600%20S%2C%20West%20Jordan%2C%20UT%2084081!5e0!3m2!1sen!2sus!4v1621279767495!5m2!1sen!2sus"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy">
      </iframe>
    </div>
  </div>
</div>