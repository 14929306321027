import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of} from 'rxjs';
import { GalleryItem } from './gallery-item';

@Injectable({
  providedIn: 'root'
})
export class GalleryItemService {
  private galleryItemsURL = 'api/galleryItems';

  constructor(private http: HttpClient) { }

  getGalleryItems(): Observable<GalleryItem[]> {
    return this.http.get<GalleryItem[]>(this.galleryItemsURL);
  }
}
