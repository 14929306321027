<div class="container">
  <!-- Heading with image -->
  <div class="row">
    <div class="col">
      <h1 class="text-center">About Us</h1>
      <img src="assets/images/painted-hands-small.jpg" class="rounded mx-auto d-block" alt="child with painted hands">
    </div>
  </div>

  <div class="row">
    <!-- About the school -->
    <div class="col">
      <h2>The School</h2>
      <p>Nulla tincidunt eros ac justo faucibus, sed suscipit arcu lacinia. Nunc dolor dolor, laoreet at vestibulum vel, facilisis id sapien. Sed quis nibh ut eros imperdiet imperdiet eu a augue.</p>
      <ul>
        <li>Proin dapibus lectus vel lacinia ultricies.</li>
        <li>Donec eleifend diam finibus ultrices interdum.</li>
        <li>Nullam hendrerit arcu eget accumsan porta.</li>
        <li>Aliquam ac erat in quam aliquam venenatis quis vitae justo.</li>
        <li>Nam iaculis turpis quis leo luctus egestas.</li>
      </ul>
    </div>

    <!-- Our history -->
    <div class="col">
      <h2>Our History</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eleifend nulla at finibus accumsan. Nunc pellentesque ipsum vel ante viverra malesuada. Curabitur vitae sodales nibh. Vestibulum at malesuada lacus. Donec vel nunc in nibh condimentum porta. Integer vel ex urna. Quisque ac odio metus. Morbi suscipit libero et fringilla placerat. Phasellus quis dapibus massa. Quisque dignissim elit nibh, nec pharetra magna pretium nec. Curabitur quis enim tristique, ultricies mi sed, bibendum urna. Vestibulum vestibulum malesuada tellus ut iaculis. Nullam arcu risus, rhoncus convallis gravida sit amet, finibus id diam.</p>
    </div>
  </div>
</div>