<div class="carouselOuter">
  <div id="galleryCarousel" class="carousel slide carousel-fade" data-interval="1000" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" 
            *ngFor="let galleryItem of galleryItems let i=index" 
            [ngClass]="{'active': i == 0 }">
        <img src="{{galleryItem.src}}" 
             class="d-block w-100 img-fluid" 
             alt="{{galleryItems.alt}}">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#galleryCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#galleryCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>