<!-- Main App Template -->

<!-- Header -->
<app-header></app-header>

<!-- Routing -->
<router-outlet></router-outlet>

<!-- Footer -->
<app-footer></app-footer>
